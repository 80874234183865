import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import { CustomPageProps } from "@utils/types"
import { BaseLayout } from "@layouts/BaseLayout"
import { Products } from "@components/products/Products"

const ProductsPage: React.FC<CustomPageProps> = ({
  data,
  pageContext,
  location,
}) => {
  // @ts-ignore
  const products = data.directus.items.products
  // @ts-ignore
  const seo = data.directus.items.seo
  const seoContent = seo.translations[0]

  return (
    <BaseLayout
      theme="gray"
      pageContext={pageContext}
      location={location}
      title={seoContent.title_products || "Продукция"}
      description={
        seoContent.description_products ||
        "В своей работе мы придерживаемся принципа, что каждый успешный бизнес является уникальным. Именно отличие от других, умение найти в бизнесе свою исключительность обеспечивает преимущества, позволяющие опередить конкурентов. Уникальный бизнес требует нестандартных решений в плане обеспечения его оборудованием. Предлагая холодильное оборудование в Киеве и по всей стране, мы готовы к проектированию нестандартных решений, которые будут идеально соответствовать потребностям наших клиентов и условий их бизнеса."
      }
      image={getSrc(seo.products_image.imageFile)}
    >
      <Products products={products} />
    </BaseLayout>
  )
}

export const query = graphql`
  query ProductsRuPage {
    directus {
      items {
        products {
          id
          sort
          slug
          translations(filter: { languages_code: { code: { _eq: "ru-RU" } } }) {
            title
            description
          }
        }
        seo {
          translations(filter: { languages_code: { code: { _eq: "ru-RU" } } }) {
            title_products
            description_products
          }
          products_image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 1200, height: 627)
              }
            }
          }
        }
      }
    }
  }
`

export default ProductsPage
