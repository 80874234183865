import React, { useContext } from "react"
import { Link } from "gatsby"
import cx from "classnames"

import * as s from "./ProductItem.module.sass"
import { LayoutContext } from "@layouts/BaseLayout"

type ProductItemProps = {
  className?: string
  link: string
  title: string
  description: string
}

export const ProductItem: React.FC<ProductItemProps> = ({
  className,
  link,
  title,
  description,
}) => {
  const context = useContext(LayoutContext)

  return (
    <Link
      to={
        context.langKey === "ua" ? `/products/${link}` : `/ru/products/${link}`
      }
      className={cx(s.root, className)}
    >
      <h2 className={s.header}>{title}</h2>
      <p>{description}</p>
    </Link>
  )
}
