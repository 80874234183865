import React, { useContext } from "react"
import cx from "classnames"

import { LayoutContext } from "@layouts/BaseLayout"
import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import { ProductItem } from "@components/products/ProductItem"
import { LinkToForm } from "@components/common/LinkToForm"
import TrianglesFirst from "@icons/ProdcutsCommonDesign1.svg"
import TrianglesSecond from "@icons/ProdcutsCommonDesign2.svg"

import * as s from "./Products.module.sass"

type ProductProps = {
  id: string
  slug: string
  translations: {
    title: string
    description: string
  }[]
}

type ProductsProps = {
  products: ProductProps[]
  className?: string
}

export const Products: React.FC<ProductsProps> = ({ products, className }) => {
  const { langKey } = useContext(LayoutContext)

  return (
    <section className={cx(s.root, className)}>
      <TrianglesFirst className={s.triangleFirst} />
      <TrianglesSecond className={s.triangleSecond} />
      <Container>
        <Row className={s.row}>
          <LinkToForm className={s.link} />
          <h1 className={s.header}>
            {langKey === "ua" ? "Продукція" : "Продукция"}
          </h1>
          <div className={s.wrapper}>
            {products.map(product => (
              <ProductItem
                key={product.id}
                link={product.slug}
                title={product.translations[0].title}
                description={product.translations[0].description}
              />
            ))}
          </div>
        </Row>
      </Container>
    </section>
  )
}
